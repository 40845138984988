import { Flex, Text, Skeleton } from '@chakra-ui/react';

import { AnimatePresence } from 'framer-motion';
import { useEffect, useState, type FunctionComponent, type ReactNode } from 'react';
import type { ExitAnimation } from './CategorizeSection';
import { ChakraMotionBox } from 'src/components/shared/ChakraMotionBox';
import { useTranslation } from 'react-i18next';

interface CardProps {
  index: number;
  exitAnimation: ExitAnimation;
  loading: boolean;
  topCard: boolean;
  children: ReactNode[] | ReactNode;
}

export const Card = ({ index, exitAnimation, loading, topCard, children }: CardProps) => {
  const TOP_OFFSET = 15;
  const BASE_Z_INDEX = 10;

  const scale = (idx: number) => 1 - idx * 0.05;

  const variants: Record<string, object> = {
    initial: {
      top: (index + 1) * TOP_OFFSET,
      scale: scale(index + 1),
      opacity: index > 1 ? 0.3 : 1,
    },
    base: {
      top: index * TOP_OFFSET,
      scale: scale(index),
      opacity: 1,
      y: 0,
    },
    review: {
      x: -250,
      opacity: 0,
      rotate: -25,
      zIndex: BASE_Z_INDEX + 1,
    },
    skip: {
      x: 250,
      opacity: 0,
      rotate: 25,
      zIndex: BASE_Z_INDEX + 1,
    },
    undo: { y: -250, opacity: 0 },
    none: {
      transition: {
        duration: '0',
      },
      /* NOTE: Don't animate the exit of the placeholder cards after loading */
    },
  };

  const getExitAnim = () => {
    // Remove the placeholder skeleton cards immediately
    if (exitAnimation === 'none') {
      return 'none';
    }

    // Handle skip or confirm animations
    if (topCard) {
      return exitAnimation;
    }

    // Handle back/ undo to shift cards down
    return 'initial';
  };

  return (
    <ChakraMotionBox
      w={'100%'}
      maxW={'25rem'}
      position="absolute"
      variants={variants}
      animate={loading ? 'initial' : 'base'}
      initial={exitAnimation === 'undo' ? 'undo' : 'initial'}
      exit={getExitAnim()}
      transition={{ type: 'tween', ease: 'linear', duration: '0.2' }}
      zIndex={BASE_Z_INDEX - index}
    >
      <Flex
        h={'fit-content'}
        minH="10rem"
        borderRadius={'lg'}
        p={4}
        boxShadow={'md'}
        bg={'containerBg'}
      >
        {children}
      </Flex>
    </ChakraMotionBox>
  );
};

interface CardStackProps<Entity> {
  entities: Entity[];
  entityType: 'transactions' | 'automationSuggestions';
  total: number;
  loading: boolean;
  offset: number;
  exitAnimation: ExitAnimation;
  /** Number of cards to render in the stack */
  numCards: number;
  Content: FunctionComponent<{ loading: boolean; entity: Entity; index: number }>;
}

const EST_ENTITY_PER_MIN = 10;

export const CardStack = <Entity extends { id: string }>({
  entities,
  entityType,
  loading,
  total,
  offset,
  exitAnimation,
  numCards,
  Content,
}: CardStackProps<Entity>) => {
  const { t } = useTranslation();
  const [cards, setCards] = useState<CardStackProps<Entity>['entities']>([]);

  useEffect(() => {
    setCards(entities.slice(offset, offset + numCards));
  }, [entities, offset, numCards]);

  const estimate = Math.max(Math.round((total - offset) / EST_ENTITY_PER_MIN), 1);

  return (
    <Flex flexDir="column" gap={4} width={{ base: '100%', md: '40ch' }} alignSelf="center">
      <Flex position="relative" justifyContent={'center'} h={'11.5rem'}>
        <AnimatePresence>
          {cards.map((c, i) => {
            return (
              <Card
                key={c.id}
                index={i}
                exitAnimation={exitAnimation}
                loading={loading}
                topCard={i === 0}
              >
                <Content entity={c} loading={loading} index={i} />
              </Card>
            );
          })}
        </AnimatePresence>
      </Flex>
      <Skeleton isLoaded={!loading} w="full">
        <Text color="subtleText" textAlign={'center'}>
          {`${t(`categorize.cardStack.${entityType}.remainingText`, {
            count: total - offset,
          })} • ${t('categorize.cardStack.estimateText', {
            count: estimate,
          })}`}
        </Text>
      </Skeleton>
    </Flex>
  );
};
