import { useQuery } from '@apollo/client';
import {
  Button,
  SimpleGrid,
  Skeleton,
  useColorModeValue,
  Text,
  Kbd,
  useBreakpointValue,
} from '@chakra-ui/react';
import { transparentize } from '@chakra-ui/theme-tools';
import { capitalize } from '@flume-finance/common';
import {
  type GetCategoryGroupsData,
  GET_CATEGORY_GROUPS,
  GET_CATEGORY_GROUPS_PLACEHOLDER,
} from 'src/graphql/GetCategoryGroups';
import { theme } from '@flume-finance/ui';
import { useEffect, useMemo } from 'react';
import { getQueryData } from 'src/util/graphql';

interface CategoryButtonsProps {
  // Take in loading state of entities to keep categories hidden until load
  otherLoaded: boolean;
  updateCategory: (categoryId: string) => void;
}

/**
 * Generate bidirectional mappings of keyboard key and category id.
 * Attempt to use the first letter of each category name, but if it's taken move on to the next letter.
 * If there are no available letters in the name, then default to the next available letter in the alphabet
 *
 * @param categories An array of categories
 * @returns Two Maps of [key, categoryId] and [categoryId, key]
 */
const assignShortcutKeys = (categories: { id: string; name: string }[]) => {
  const keyToCategory = new Map<string, string>();
  const categoryToKey = new Map<string, string>();

  const categoriesToAssign = new Set(categories);
  let currCharIdx = 0;

  // Assign each category a shortcut, stop attempting after the first 20 characters to avoid infinite loops
  while (categoriesToAssign.size > 0 && currCharIdx < 20) {
    for (const cta of categoriesToAssign) {
      if (currCharIdx < cta.name.length) {
        const char = cta.name.charAt(currCharIdx).toLocaleLowerCase();
        // NOTE: Only allow alphabetical characters to be shortcuts so as not to interfere with other shortcuts
        if (!keyToCategory.has(char) && char.match(/[a-z]/i)) {
          categoryToKey.set(cta.id, char);
          keyToCategory.set(char, cta.id);
          categoriesToAssign.delete(cta);
        }
      } else {
        // If no letters in the category name were available, assign the next available letter in the alphabet
        for (let cOffset = 0; cOffset < 26; cOffset++) {
          const char = String.fromCharCode(97 + cOffset);
          if (!keyToCategory.has(char)) {
            categoryToKey.set(cta.id, char);
            keyToCategory.set(char, cta.id);
            categoriesToAssign.delete(cta);
            break;
          }
        }
      }
    }
    currCharIdx++;
  }

  return { categoryToKey, keyToCategory };
};

export const CategoryButtons = ({ otherLoaded, updateCategory }: CategoryButtonsProps) => {
  const { data } = useQuery<GetCategoryGroupsData>(GET_CATEGORY_GROUPS);
  const {
    isInitialLoaded,
    queryData: { categoryGroups },
  } = getQueryData(data, GET_CATEGORY_GROUPS_PLACEHOLDER);

  const isLoaded = isInitialLoaded && otherLoaded;

  const fontColor = useColorModeValue(
    (color: string) => `${color}.800`,
    (color: string) => `${color}.200`,
  );
  const bgColor = (color: string, opacity = 0.4) => transparentize(`${color}.400`, opacity)(theme);

  const categories = categoryGroups
    .flatMap(({ categories: cs }) => cs)
    .sort((a, b) => a.name.localeCompare(b.name));

  const showKeyboardShortcuts = useBreakpointValue(
    { base: false, md: true },
    { ssr: false, fallback: 'base' },
  );
  const { categoryToKey, keyToCategory } = useMemo(
    () => assignShortcutKeys(categories),
    [categories],
  );

  useEffect(() => {
    const handleKeyUp = (e: KeyboardEvent) => {
      const cId = keyToCategory.get(e.key);
      if (cId && isLoaded) {
        updateCategory(cId);
      }
    };

    document.addEventListener('keyup', handleKeyUp, { passive: true });

    // Clean up the event handler on unmount
    return () => {
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [isLoaded, keyToCategory, updateCategory]);

  return (
    <SimpleGrid columns={{ base: 2, md: 3, lg: 4 }} gap={2} flexShrink={1} overflowY="auto" p={2}>
      {categories.map(({ id, color, name }) => (
        <Skeleton key={id} isLoaded={isLoaded} width="100%">
          <Button
            size="sm"
            colorScheme={color}
            bg={bgColor(color)}
            _hover={{ bg: bgColor(color, 0.6) }}
            _active={{ bg: bgColor(color, 0.8) }}
            w="full"
            justifyContent={'space-between'}
            onClick={() => updateCategory(id)}
            rightIcon={
              <Kbd
                color={fontColor(color)}
                hidden={!showKeyboardShortcuts || categoryToKey.get(id) === undefined}
              >
                {categoryToKey.get(id)}
              </Kbd>
            }
          >
            <Text isTruncated color={fontColor(color)}>
              {capitalize(name)}
            </Text>
          </Button>
        </Skeleton>
      ))}
    </SimpleGrid>
  );
};
