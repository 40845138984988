import { useQuery } from '@apollo/client';
import {
  Alert,
  type AlertProps,
  type ComponentWithAs,
  Flex,
  Heading,
  Icon,
  type IconProps,
  Skeleton,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import CategoryIcon from 'src/assets/icons/navbar/icons8-tags.svg?react';
import { GET_TRANSACTIONS, GET_TRANSACTIONS_PLACEHOLDER } from 'src/graphql/GetTransactions';
import { Link as RouterLink } from 'react-router-dom';
import { ErrorWithRefetch } from 'src/components/shared/ErrorWithRefetch';
import { CheckIcon, ChevronRightIcon } from '@chakra-ui/icons';
import type { FunctionComponent } from 'react';
import { ReviewedFilter } from 'src/graphql/__generated__/graphql';

export const ActionItemsSection = () => {
  const { t } = useTranslation();

  return (
    <Flex flexDir={'column'} gap={2}>
      <Heading size="md">{t('overview.actionItems.title')}</Heading>
      <TransactionCategorizeAlert />
    </Flex>
  );
};

export const TransactionCategorizeAlert = () => {
  const { t } = useTranslation();

  const {
    data: reviewDataFetched,
    error: reviewError,
    refetch: reviewDataRefetch,
  } = useQuery(GET_TRANSACTIONS, {
    variables: {
      cursor: null,
      sort: { fieldId: 'date', desc: true },
      filters: {
        category: null,
        payee: null,
        reviewed: ReviewedFilter.NeedsReview,
      },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });

  const reviewData =
    reviewDataFetched === undefined ? GET_TRANSACTIONS_PLACEHOLDER : reviewDataFetched;

  const isLoading = reviewDataFetched === undefined;

  if (reviewError) {
    return <ErrorWithRefetch refetch={reviewDataRefetch} message="Failed to load action items." />;
  }

  const transactionCount = reviewData.transactions.total;
  if (transactionCount === 0) {
    return (
      <Flex alignItems={'center'} gap={2}>
        <CheckIcon />
        <Text>Everything is in order</Text>
      </Flex>
    );
  }

  return (
    <ActionItemAlert
      body={t('overview.actionItems.transactionCategorizeAlert.body', {
        transactionCount,
      })}
      buttonIcon={CategoryIcon}
      isLoading={isLoading}
    />
  );
};

interface ActionItemAlertProps {
  body: string;
  buttonIcon: FunctionComponent<React.SVGProps<SVGSVGElement>> | ComponentWithAs<'svg', IconProps>;
  isLoading: boolean;
  status?: AlertProps['status'];
}

const ActionItemAlert = ({
  body,
  buttonIcon,
  isLoading,
  status = 'info',
}: ActionItemAlertProps) => {
  return (
    <Alert
      status={status}
      variant={'left-accent'}
      flex={1}
      alignItems="center"
      justifyContent={'space-between'}
      cursor={'pointer'}
      as={RouterLink}
      to="/categorize"
    >
      <Skeleton isLoaded={!isLoading}>
        <Flex alignItems={'center'} gap={2}>
          <Icon as={buttonIcon} fill={'iconColor'} width={'1.125rem'} height={'1.125rem'} />
          <Text fontWeight="bold">{body}</Text>
        </Flex>
      </Skeleton>
      <ChevronRightIcon w={'1.125rem'} h={'1.125rem'} />
    </Alert>
  );
};
