import { useQuery, useReactiveVar } from '@apollo/client';
import { Flex, Heading, Spinner, Text, useColorModeValue } from '@chakra-ui/react';
import { getColor } from '@chakra-ui/theme-tools';
import { theme } from '@flume-finance/ui';
import { useTranslation } from 'react-i18next';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { graphInterval } from 'src/cache';
import { intervalToChartParams } from 'src/components/insights/YearPicker';
import { ErrorWithRefetch } from 'src/components/shared/ErrorWithRefetch';
import {
  GET_INCOME_VS_EXPENSE,
  type GetIncomeVsExpenseData,
  type GetIncomeVsExpenseInput,
} from 'src/graphql/GetIncomeVsExpense';
import { formatCurrency, sum, sumObj } from 'src/util';
import { unix } from 'src/util/dayjs';

export const IncomeExpenseArea = () => {
  const { t } = useTranslation();
  const graphIntervalValue = useReactiveVar(graphInterval);
  const chartParams = intervalToChartParams(graphIntervalValue);
  const { loading, error, data } = useQuery<GetIncomeVsExpenseData, GetIncomeVsExpenseInput>(
    GET_INCOME_VS_EXPENSE,
    {
      variables: {
        startDate: unix(chartParams.startDate).toDate(),
        endDate: unix(chartParams.endDate).toDate(),
        interval: chartParams.aggregationInterval,
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  const gridColor = useColorModeValue(theme.colors.gray[200], theme.colors.gray[600]);
  const fontColor = useColorModeValue(theme.colors.black, theme.colors.white);

  const fontProps = {
    stroke: fontColor,
    fontSize: 12,
    fontWeight: 'bold',
    fontFamily: theme.fonts.body,
  };

  if (error) return <ErrorWithRefetch message={t('insights.chart.errorMessage')} />;

  if (data === undefined) {
    return (
      <Flex justifyContent="center" alignItems="center" height={'100%'}>
        <Spinner size="lg" />
      </Flex>
    );
  }

  const total = sumObj<GetIncomeVsExpenseData['incomeVsExpense']['aggregations'][0]>(
    data.incomeVsExpense.aggregations,
    (ci) => sum(ci.amounts),
  );

  const dataset = data.incomeVsExpense.xLabels.map((xLabel, i) => {
    const entry: Record<string, string | number> = {
      name: xLabel,
    };

    for (const agg of data.incomeVsExpense.aggregations) {
      entry[agg.category.name] = Math.abs(agg.amounts[i]);
    }

    return entry;
  });

  return (
    <>
      <Flex justifyContent="space-between" alignItems="flex-start">
        <Heading as="h2" marginBottom={5}>
          {formatCurrency({ cents: total, currencyCode: 'USD' })}
        </Heading>
        {loading && (
          <Flex alignItems="center">
            <Spinner size={'sm'} mr={3} />
            <Text>{t('insights.chart.refreshingMessage')}</Text>
          </Flex>
        )}
      </Flex>
      <ResponsiveContainer width={'100%'} height={'90%'}>
        <BarChart data={dataset} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
          <XAxis dataKey="name" axisLine={false} tickLine={false} {...fontProps} />
          <YAxis
            tickFormatter={(val: number) =>
              formatCurrency({ cents: val, currencyCode: 'USD', hideCents: true })
            }
            axisLine={false}
            tickLine={false}
            {...fontProps}
          />
          <CartesianGrid stroke={gridColor} vertical={false} />
          <Legend verticalAlign="bottom" {...fontProps} />
          {Object.keys(dataset[0]).map((key) => {
            if (key === 'name') {
              return;
            }
            const color =
              data.incomeVsExpense.aggregations.find((agg) => agg.category.name === key)?.category
                .color ?? 'gray';

            return (
              <Bar
                key={key}
                type="monotone"
                dataKey={key}
                fillOpacity={1}
                fill={getColor(theme, `${color}.400`)}
              />
            );
          })}
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};
