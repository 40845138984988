import { InMemoryCache, makeVar, type Reference } from '@apollo/client';
import { ChartType } from './components/insights/ChartSelect';
import { GraphInterval } from './components/insights/YearPicker';
import { now } from 'src/util/dayjs';
import type { GetTransactionsQuery } from 'src/graphql/__generated__/graphql';

export const ACCOUNT_ID_PARAM = 'accountId';
export const CATEGORY_ID_PARAM = 'categoryId';
export const selectedAccountId = makeVar<string | undefined>(
  new URLSearchParams(window.location.search).get(ACCOUNT_ID_PARAM) ?? undefined,
);
export const selectedCategoryId = makeVar<string | undefined>(
  new URLSearchParams(window.location.search).get(CATEGORY_ID_PARAM) ?? undefined,
);
export const needsReviewFilter = makeVar<boolean>(false);
export const currentMonth = makeVar<number | undefined>(now().startOf('month').valueOf());
export const selectedTransactionId = makeVar<string | undefined>(undefined);

export const TRANSACTION_PAYEE_SEARCH_PARAM = 'q';
export const payeeFilter = makeVar<string | null>(
  new URLSearchParams(window.location.search).get(TRANSACTION_PAYEE_SEARCH_PARAM) ?? '',
);

export interface PlaidItemConnection {
  itemId: string;
  institutionName: string;
  status: 'importing' | 'success' | 'timeout';
}
export const plaidItemConnection = makeVar<PlaidItemConnection | undefined>(undefined);
export const selectedChart = makeVar<ChartType>(ChartType.SPEND_BY_CATEGORY);
export const graphInterval = makeVar<GraphInterval>(GraphInterval.MONTH_TO_DATE);

export const currentYear = makeVar<number>(new Date().getTime() / 1000);

export enum SaveState {
  SAVING = 'saving',
  SAVED = 'saved',
  ERRORED = 'errored',
}
export const saveState = makeVar<SaveState>(SaveState.SAVED);
export const sidebarExpanded = makeVar<boolean>(localStorage.getItem('sidebarExpanded') === 'true');
export const netWorthObfuscated = makeVar<boolean>(
  localStorage.getItem('netWorthObfuscated') === 'true',
);

export interface SubscriptionIndicatorInfo {
  status: 'info' | 'success' | 'timeout';
}
export const subscriptionIndicatorInfo = makeVar<SubscriptionIndicatorInfo | undefined>(undefined);

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        selectedTransactionId: {
          read() {
            return selectedTransactionId();
          },
        },
        transactions: {
          keyArgs: ['accountId', 'sort', 'filters'],
          merge(
            existing:
              | (Omit<GetTransactionsQuery['transactions'], 'transactions'> & {
                  transactions: Reference[];
                })
              | undefined,
            incoming: Omit<GetTransactionsQuery['transactions'], 'transactions'> & {
              transactions: Reference[];
            },
            { args },
          ) {
            if (args?.cursor === null) {
              return incoming;
            } else {
              const existingTransactions = existing ? [...existing.transactions] : [];

              // Filter out transactions if they are already present in the cache
              const existingRefs = existingTransactions.map((t) => t.__ref);
              const newTransactions = new Set([
                ...existingTransactions,
                ...incoming.transactions.filter((t) => !existingRefs.includes(t.__ref)),
              ]);

              return {
                nextCursor: incoming.nextCursor,
                transactions: Array.from(newTransactions),
                // NOTE: Maintain the original total from the first page of results
                total: existing?.total,
              };
            }
          },
          read(existing) {
            if (existing) {
              return {
                nextCursor: existing.nextCursor,
                transactions: Object.values(existing.transactions),
                total: existing.total,
              };
            }
            return undefined;
          },
        },
      },
    },
  },
});

export { cache };
