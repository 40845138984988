import { useMutation, useQuery } from '@apollo/client';
import { WarningTwoIcon } from '@chakra-ui/icons';
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Skeleton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModePreference,
  type useDisclosure,
} from '@chakra-ui/react';
import { useState } from 'react';
import { CsvUploadForm, type FieldMapping } from 'src/components/csvUpload/CsvUploadForm';
import type { CsvFile } from 'src/components/csvUpload/FileSelect';
import { EDIT_ACCOUNT } from 'src/graphql/EditAccount';
import { GET_ACCOUNT } from 'src/graphql/GetAccount';
import { IMPORT_STATEMENTS, importStatementsAction } from 'src/graphql/ImportStatements';
import {
  type CreateManualAccountArgs,
  type EditAccountMutation,
  type EditAccountMutationVariables,
  type GetAccountQuery,
  SyncMode,
} from 'src/graphql/__generated__/graphql';
import { CreateEditAccountForm } from './createAccountFlow/CreateEditAccountForm';
import { useTranslation } from 'react-i18next';
import { cache } from 'src/cache';
import { GET_ACCOUNTS } from 'src/graphql/GetAccounts';
import { MERGE_ACCOUNTS_MUTATION } from 'src/graphql/MergeAccounts';

export const UploadPanel = ({
  account,
  onClose,
}: {
  account: GetAccountQuery['account'];
  onClose: EditAccountModalProps['onClose'];
}) => {
  const colorMode = useColorModePreference();
  const [csvFiles, setCsvFiles] = useState<CsvFile[] | undefined>(undefined);
  const [fieldMapping, setFieldMapping] = useState<FieldMapping>({
    Merchant: undefined,
    Payee: undefined,
    Amount: undefined,
    Date: undefined,
    Account: undefined,
    Category: undefined,
    Notes: undefined,
    Type: undefined,
  });

  const [importStatements, { loading: importStatementsLoading }] = useMutation(IMPORT_STATEMENTS);

  if (account.syncMode === SyncMode.Automatic) {
    return (
      <HStack spacing={4}>
        <WarningTwoIcon w={6} h={6} />
        <Text fontSize="sm">
          The ability to import transactions is currently only available for manual accounts
        </Text>
      </HStack>
    );
  }

  return (
    <CsvUploadForm
      csvFiles={csvFiles}
      setCsvFiles={setCsvFiles}
      fieldMapping={fieldMapping}
      setFieldMapping={setFieldMapping}
      onSubmit={() => {
        importStatementsAction({
          accountId: account.id,
          accountType: account.type,
          csvFiles: csvFiles ?? [],
          fieldMapping,
          importStatements,
          onClose,
          colorMode,
        });
      }}
      submitting={importStatementsLoading}
    />
  );
};

export const EditPanel = ({
  account,
  onClose,
}: {
  account: GetAccountQuery['account'];
  onClose: EditAccountModalProps['onClose'];
}) => {
  const [updatedAccount, updateAccount] = useState<CreateManualAccountArgs>({
    nickname: account.nickname,
    accountNumber: account.accountNumber ?? '',
    type: account.type,
  });

  const [editAccount] = useMutation<EditAccountMutation, EditAccountMutationVariables>(
    EDIT_ACCOUNT,
    {
      optimisticResponse: {
        editAccount: { ...updatedAccount, id: account.id },
      },
    },
  );

  return (
    <CreateEditAccountForm
      account={updatedAccount}
      updateAccount={updateAccount}
      setActiveIndex={() => {
        editAccount({ variables: { editAccountArgs: { ...updatedAccount, id: account.id } } });
        onClose();
      }}
      buttonText="Save"
    />
  );
};

// Merge account panel
const MergeAccountPanel = ({
  account,
  onClose,
}: { account: GetAccountQuery['account']; onClose: () => void }) => {
  const { t } = useTranslation();
  const [selectedAccountId, setSelectedAccountId] = useState<string | undefined>();

  const { data: accountsData, loading: accountsLoading } = useQuery(GET_ACCOUNTS, {
    fetchPolicy: 'cache-and-network',
  });
  const [mergeAccounts, { loading: mergeAccountsLoading }] = useMutation(MERGE_ACCOUNTS_MUTATION);

  const handleMergeAccounts = async () => {
    if (!selectedAccountId) {
      return;
    }

    await mergeAccounts({
      variables: {
        sourceAccountId: selectedAccountId,
        targetAccountId: account.id,
      },
    });

    // Refetch the overview page data
    cache.evict({ id: 'ROOT_QUERY', fieldName: 'transactions' });
    cache.evict({ id: 'ROOT_QUERY', fieldName: 'overviewElements' });
    cache.evict({ id: 'ROOT_QUERY', fieldName: 'netWorthHistory' });
    cache.gc();

    // Close Modal
    onClose();
  };

  if (account.syncMode === SyncMode.Manual) {
    return <Text>Manual accounts cannot be merged</Text>;
  }

  return (
    <Flex direction="column" gap={4}>
      <Text>{t('accounts.editModal.mergeAccountTab.description')}</Text>
      <FormControl>
        <FormLabel>{t('accounts.editModal.mergeAccountTab.selectAccountLabel')}</FormLabel>
        <Skeleton isLoaded={!accountsLoading}>
          <Select
            placeholder={t('accounts.editModal.mergeAccountTab.placeholder')}
            onChange={(e) => setSelectedAccountId(e.target.value)}
          >
            {accountsData?.accounts
              .filter((a) => a.syncMode === SyncMode.Manual)
              .map((a) => (
                <option value={a.id}>{a.nickname}</option>
              ))}
          </Select>
        </Skeleton>
      </FormControl>

      <Button
        onClick={handleMergeAccounts}
        isDisabled={!selectedAccountId}
        isLoading={mergeAccountsLoading}
        alignSelf={'flex-end'}
      >
        {t('accounts.editModal.mergeAccountTab.mergeButton')}
      </Button>
    </Flex>
  );
};

interface EditAccountModalProps {
  isOpen: boolean;
  onClose: ReturnType<typeof useDisclosure>['onClose'];
  accountId: GetAccountQuery['account']['id'] | undefined;
}
export const EditAccountModal = ({ isOpen, onClose, accountId }: EditAccountModalProps) => {
  const { t } = useTranslation();
  const { data } = useQuery(GET_ACCOUNT, {
    variables: { id: accountId as string },
    skip: accountId === undefined,
  });

  const account = data?.account;

  if (account === undefined) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" trapFocus={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text>{account.nickname}</Text>
        </ModalHeader>
        <ModalBody>
          <Tabs variant={'enclosed'}>
            <TabList>
              <Tab>{t('accounts.editModal.tabs.editTabLabel')}</Tab>
              <Tab>{t('accounts.editModal.tabs.importTabLabel')}</Tab>
              <Tab>{t('accounts.editModal.tabs.mergeAccountTabLabel')}</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <EditPanel account={account} onClose={onClose} />
              </TabPanel>
              <TabPanel>
                <UploadPanel account={account} onClose={onClose} />
              </TabPanel>
              <TabPanel>
                <MergeAccountPanel account={account} onClose={onClose} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  );
};
