import { useQuery } from '@apollo/client';
import { Checkbox, Flex, Select, Text, useBreakpointValue, useDisclosure } from '@chakra-ui/react';
import {
  type CellContext,
  type ColumnDef,
  type ColumnFiltersState,
  type RowSelectionState,
  type SortingState,
  type VisibilityState,
  createColumnHelper,
} from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiTransfer } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import TransactionIcon from 'src/assets/icons/icons8-ledger.svg?react';
import { ACCOUNT_ID_PARAM } from 'src/cache';
import { EmptyListCta } from 'src/components/shared/EmptyListCta';
import { SharedTable } from 'src/components/shared/SharedTable';
import { useKeyPress } from 'src/components/shared/hooks/useKeyPress';
import { GET_TRANSACTIONS, GET_TRANSACTIONS_PLACEHOLDER } from 'src/graphql/GetTransactions';
import {
  type FilterOptions,
  type GetTransactionsQuery,
  type GetTransactionsQueryVariables,
  ReviewedFilter,
} from 'src/graphql/__generated__/graphql';
import { formatCurrency } from 'src/util';
import { AccountsSelect } from './AccountSelect';
import { CategorySelect } from './CategorySelect';
import { EditTransactionModal } from './EditModal/EditTransactionModal';
import { BulkReviewActions } from './table/BulkReviewActions';
import { CategoryCell } from './table/CategoryCell';
import { CategoryTag } from 'src/components/shared/CategoryTag';
import { capitalize } from '@flume-finance/common';

type ColumnData = GetTransactionsQuery['transactions']['transactions'][0];
// biome-ignore lint/suspicious/noExplicitAny: Quirk of @tanstack/react-table and typescript
type ColumnDefs = ColumnDef<ColumnData, any>[];

const useRowSelection = () => {
  const [previousSelectedRowIndex, setPreviousSelectedRowIndex] = useState<number | null>(0);
  const shiftPressed = useKeyPress('Shift');

  const onChange = (cell: CellContext<ColumnData, unknown>) => {
    // Toggle the current row
    const clickedRowIndex = cell.row.index;
    const nextCheckedState = !cell.row.getIsSelected();
    cell.row.toggleSelected(nextCheckedState);

    // Toggle the rows between the previous and current row if shift is held
    if (previousSelectedRowIndex !== null && shiftPressed) {
      const start = Math.min(previousSelectedRowIndex, clickedRowIndex);
      const end = Math.max(previousSelectedRowIndex, clickedRowIndex);

      const rowsByIndex = new Map(cell.table.getRowModel().flatRows.map((r) => [r.index, r]));
      for (let i = start; i <= end; i++) {
        rowsByIndex.get(i)?.toggleSelected(nextCheckedState);
      }
    }

    setPreviousSelectedRowIndex(clickedRowIndex);
  };

  return [onChange];
};

const initialFilters = (): ColumnFiltersState => {
  const filters = [];

  const accountId = new URLSearchParams(window.location.search).get(ACCOUNT_ID_PARAM) ?? undefined;
  if (accountId) {
    filters.push({ id: 'account.id', value: accountId });
  }

  return filters;
};

function TransactionTable() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [selectedTransactionId, selectTransactionId] = useState<string | undefined>(undefined);

  const [sorting, setSorting] = useState<SortingState>([{ id: 'date', desc: true }]);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(initialFilters());
  const [globalFilter, setGlobalFilter] = useState<string | null>(null);
  const [onRowChange] = useRowSelection();

  const [previousColumnFilters, setPreviousColumnFilters] = useState(columnFilters);
  const [previousGlobalFilter, setPreviousGlobalFilter] = useState(globalFilter);

  const columnFilterById = useMemo(
    () => new Map(columnFilters.map((cf) => [cf.id, cf.value as string])),
    [columnFilters],
  );

  const filterValues: GetTransactionsQueryVariables['filters'] = useMemo(() => {
    const filters: FilterOptions = {
      reviewed: ReviewedFilter.Any,
      payee: null,
    };

    if (globalFilter && globalFilter.length > 0) {
      filters.payee = globalFilter;
    }

    for (const cf of columnFilters) {
      switch (cf.id) {
        case 'category': {
          const val = cf.value as string;
          if (val === 'uncategorized') {
            filters.category = null;
          } else if (val === 'all') {
            filters.category = undefined;
          } else {
            filters.category = val;
          }
          break;
        }
        case 'reviewed': {
          filters.reviewed = cf.value as ReviewedFilter;
          break;
        }
      }
    }

    return filters;
  }, [globalFilter, columnFilters]);

  const variables = useMemo(
    () => ({
      accountId: columnFilterById.get('account.id'),
      cursor: null,
      sort: sorting[0] ? { fieldId: sorting[0].id, desc: sorting[0].desc } : null,
      filters: filterValues,
    }),
    [sorting[0], filterValues, columnFilterById],
  );

  const { error, data, previousData, fetchMore, loading, refetch } = useQuery(GET_TRANSACTIONS, {
    variables,
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      setPreviousColumnFilters(columnFilters);
      setPreviousGlobalFilter(globalFilter);
    },
  });

  const hiddenColumns =
    useBreakpointValue<VisibilityState>(
      {
        base: { notes: false, 'account.id': false, reviewed: false, category: false },
        md: { 'account.id': false, reviewed: false, notes: false },
        xl: { reviewed: false },
      },
      { ssr: false, fallback: 'xl' },
    ) ?? {};

  const isFilterChanged =
    JSON.stringify(columnFilters) !== JSON.stringify(previousColumnFilters) ||
    globalFilter !== previousGlobalFilter;

  const columnHelper = createColumnHelper<ColumnData>();

  const mobileColumns = useMemo(
    () => [
      columnHelper.display({
        id: 'checkbox',
        header: ({ table }) => (
          <Flex
            justifyContent={'center'}
            alignItems={'center'}
            flex={1}
            width="30px"
            height="25px"
            my={'-0.5rem'}
            mx={{ base: '-0.5rem', md: '-1rem' }}
          >
            <Checkbox
              isChecked={table.getIsAllRowsSelected() || table.getIsSomeRowsSelected()}
              isIndeterminate={table.getIsSomeRowsSelected()}
              onChange={table.getToggleAllRowsSelectedHandler()}
              size="sm"
            />
          </Flex>
        ),
        meta: {
          flexBasis: 30,
        },
        cell: (cell) => {
          const onChange = () => onRowChange(cell);
          return (
            <Flex
              onClick={(e) => {
                // Prevent checkbox click from being handle by the underlying row which opens the transaction modal
                e.stopPropagation();
                // Allow the user to just miss clicking the checkbox and still register it
                onChange();
              }}
              flex={1}
              justifyContent="center"
              alignItems={'center'}
              // HACK: For cell data to fill <td> so we can capture clicks outside the checkbox. my and mx must be the opposite of the td padding in the theme
              height={'48px'}
              my={'-0.4rem'}
              mx={{ base: '-0.5rem', md: '-1rem' }}
            >
              <Checkbox
                id={cell.row.id}
                isChecked={cell.row.getIsSelected()}
                size="sm"
                onChange={onChange}
              />
            </Flex>
          );
        },
      }),
      columnHelper.accessor((tr) => tr.payee, {
        id: 'merchant',
        header: 'Merchant',
        enableSorting: false,
        enableGlobalFilter: true,
        meta: {
          flexBasis: '4rem',
          flexGrow: 2,
        },
        cell: (props) => {
          const transaction = props.row.original;

          return (
            <Flex flexDir={'column'} gap={'px'}>
              <Text fontSize="sm" isTruncated fontWeight={transaction.reviewed ? 'normal' : 'bold'}>
                {transaction.payee}
              </Text>
              <Flex alignItems={'center'} gap={1}>
                <CategoryTag
                  categoryId={transaction?.category?.id}
                  size="xs"
                  hideIcon={true}
                  isTransfer={!!transaction?.linkedTransfer}
                />
                <Text
                  fontSize="xs"
                  isTruncated
                  textColor={'subtleText'}
                  fontWeight={transaction.reviewed ? 'normal' : 'bold'}
                >
                  {transaction.notes}
                </Text>
              </Flex>
            </Flex>
          );
        },
      }),
      columnHelper.accessor((tr) => tr.category, {
        id: 'category',
        header: 'Category',
        enableSorting: false,
        size: 50,
        meta: {
          flexBasis: 50,
          flexGrow: 1,
          flexShrink: 1,
          FilterComponent: (props) => <CategorySelect filterMode={true} {...props} />,
        },
        cell: (props) => {
          return <CategoryCell transaction={props.row.original} />;
        },
      }),
      columnHelper.accessor((tr) => tr.amount, {
        id: 'amount',
        header: 'Amount',
        sortDescFirst: true,
        meta: {
          flexBasis: '6.5rem',
          flexShrink: 0,
        },
        cell: (props) => {
          const transaction = props.row.original;
          return (
            <Flex flexDir={'column'} alignItems="flex-end">
              <Text
                fontSize="xs"
                color={'subtleText'}
                fontWeight={transaction.reviewed ? 'normal' : 'bold'}
              >
                {transaction.date.toLocaleDateString()}
              </Text>
              <Text fontSize="sm" fontWeight={transaction.reviewed ? 'normal' : 'bold'}>
                {formatCurrency({
                  cents: transaction.amount,
                  currencyCode: transaction.currencyCode,
                })}
              </Text>
            </Flex>
          );
        },
      }),
      columnHelper.accessor((tr) => tr.account.id, {
        id: 'account.id',
        header: 'Account',
        meta: {
          flexShrink: 1,
          flexBasis: 250,
          FilterComponent: AccountsSelect,
        },
        enableSorting: false,
        cell: (props) => {
          const transaction = props.row.original;
          return (
            <Text isTruncated fontWeight={transaction.reviewed ? 'normal' : 'bold'}>
              {transaction.account.nickname}
            </Text>
          );
        },
      }),
      columnHelper.accessor((tr) => tr.reviewed, {
        id: 'reviewed',
        header: 'Review Status',
        enableSorting: false,
        enableColumnFilter: true,
        enableHiding: true,
        meta: {
          FilterComponent: ({ value, onChange }) => {
            return (
              <Select
                size={'sm'}
                onChange={(e) => onChange(e.target.value)}
                value={value}
                borderRadius={6}
              >
                {Object.keys(ReviewedFilter).map((f) => (
                  <option value={f}>{capitalize(f)}</option>
                ))}
              </Select>
            );
          },
        },
      }),
    ],
    [onRowChange, columnHelper],
  );

  const desktopColumns = useMemo<ColumnDefs>(
    () => [
      columnHelper.display({
        id: 'checkbox',
        header: ({ table }) => (
          <Checkbox
            isChecked={table.getIsAllRowsSelected() || table.getIsSomeRowsSelected()}
            isIndeterminate={table.getIsSomeRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
          />
        ),
        meta: {
          flexBasis: 50,
        },
        cell: (cell) => {
          const onChange = () => onRowChange(cell);
          return (
            <Flex
              onClick={(e) => {
                // Prevent checkbox click from being handle by the underlying row which opens the transaction modal
                e.stopPropagation();
                // Allow the user to just miss clicking the checkbox and still register it
                onChange();
              }}
              flex={1}
              justifyContent="center"
              // HACK: For cell data to fill <td> so we can capture clicks outside the checkbox. my and mx must be the opposite of the td padding in the theme
              height={'48px'}
              my={'-0.5rem'}
              mx={{ base: '-0.5rem', md: '-1rem' }}
            >
              <Checkbox
                id={cell.row.id}
                isChecked={cell.row.getIsSelected()}
                size="md"
                onChange={onChange}
              />
            </Flex>
          );
        },
      }),
      columnHelper.accessor((tr) => new Date(tr.date).toLocaleDateString(), {
        id: 'date',
        header: 'Date',
        meta: {
          flexBasis: 130,
        },
        sortDescFirst: true,
        cell: (props) => {
          const transaction = props.row.original;

          return (
            <Text fontWeight={transaction.reviewed ? 'normal' : 'bold'}>
              {transaction.date.toLocaleDateString()}
            </Text>
          );
        },
      }),
      columnHelper.accessor((tr) => tr.payee, {
        id: 'merchant',
        header: 'Merchant',
        meta: {
          flexGrow: 2,
          flexBasis: 180,
        },
        enableGlobalFilter: true,
        enableSorting: false,
        cell: (props) => {
          const transaction = props.row.original;
          return (
            <Text fontWeight={transaction.reviewed ? 'normal' : 'bold'} isTruncated>
              {transaction.payee}
            </Text>
          );
        },
      }),
      columnHelper.accessor((tr) => tr.notes, {
        id: 'notes',
        header: 'Notes',
        enableSorting: false,
        enableGlobalFilter: true,
        meta: {
          flexGrow: 3,
          flexBasis: 150,
        },
        cell: (props) => {
          const transaction = props.row.original;
          return (
            <Text w="full" isTruncated fontWeight={transaction.reviewed ? 'normal' : 'bold'}>
              {transaction.notes}
            </Text>
          );
        },
      }),
      columnHelper.accessor((tr) => tr.account.id, {
        id: 'account.id',
        header: 'Account',
        meta: {
          flexShrink: 1,
          flexBasis: 250,
          FilterComponent: AccountsSelect,
        },
        enableSorting: false,
        cell: (props) => {
          const transaction = props.row.original;
          return (
            <Text isTruncated fontWeight={transaction.reviewed ? 'normal' : 'bold'}>
              {transaction.account.nickname}
            </Text>
          );
        },
      }),
      columnHelper.accessor((tr) => tr.category, {
        id: 'category',
        header: 'Category',
        enableSorting: false,
        meta: {
          flexBasis: 200,
          flexShrink: 1,
          FilterComponent: (props) => <CategorySelect filterMode={true} {...props} />,
        },
        cell: (props) => {
          const transaction = props.row.original;
          return (
            <Flex justify="space-between" align="center">
              <CategoryCell transaction={transaction} />
              {transaction.linkedTransfer && <BiTransfer size={25} />}
            </Flex>
          );
        },
      }),
      columnHelper.accessor((tr) => tr.amount, {
        id: 'amount',
        header: 'Amount',
        sortDescFirst: true,
        meta: {
          flexBasis: 130,
        },
        cell: (props) => {
          const transaction = props.row.original;

          return (
            <Text fontWeight={transaction.reviewed ? 'normal' : 'bold'} align="right">
              {formatCurrency({
                cents: transaction.amount,
                currencyCode: transaction.currencyCode,
              })}
            </Text>
          );
        },
      }),
      columnHelper.accessor((tr) => tr.reviewed, {
        id: 'reviewed',
        header: 'Review Status',
        enableSorting: false,
        enableColumnFilter: true,
        enableHiding: true,
        meta: {
          FilterComponent: ({ value, onChange }) => {
            return (
              <Select
                size={'sm'}
                onChange={(e) => onChange(e.target.value)}
                value={value}
                borderRadius={6}
              >
                {Object.keys(ReviewedFilter).map((f) => (
                  <option value={f}>{capitalize(f)}</option>
                ))}
              </Select>
            );
          },
        },
      }),
    ],
    [onRowChange, columnHelper],
  );

  const columns = useBreakpointValue(
    {
      base: mobileColumns,
      md: desktopColumns,
    },
    { ssr: false, fallback: 'md' },
  ) as ColumnDefs;

  const transactionData =
    data?.transactions.transactions ?? previousData?.transactions.transactions;
  const isInitialLoaded = transactionData !== undefined;
  const transactions: ColumnData[] = useMemo(() => {
    return isInitialLoaded
      ? transactionData || []
      : GET_TRANSACTIONS_PLACEHOLDER.transactions.transactions;
  }, [isInitialLoaded, transactionData]);

  const selectedTransaction =
    selectedTransactionId &&
    data?.transactions.transactions.find((tr) => tr.id === selectedTransactionId);

  return (
    <Flex direction="column" flex={1} gap={{ base: 2, md: 4 }} overflow="hidden">
      <SharedTable
        columns={columns}
        data={transactions}
        visibilityState={hiddenColumns}
        sortState={{ sorting, setSorting }}
        rowSelectionState={{ rowSelection, setRowSelection }}
        columnFilterState={{ columnFilters, setColumnFilters }}
        globalFilterState={{ globalFilter, setGlobalFilter }}
        ActionRow={BulkReviewActions}
        getRowColor={(row) => {
          if (row.getIsSelected() || row.original.id === selectedTransactionId) {
            return 'selectedTableRow';
          } else if (!row.original.reviewed) {
            return 'activeTableRow';
          } else {
            return 'inactiveTableRow';
          }
        }}
        hasMore={data?.transactions.nextCursor !== null}
        refetch={refetch}
        fetchMore={() => {
          fetchMore({
            variables: {
              cursor: data?.transactions.nextCursor,
            },
          });
        }}
        isLoading={loading && isInitialLoaded && isFilterChanged}
        isInitialLoaded={isInitialLoaded}
        isFetchingMore={loading && !isFilterChanged}
        error={error}
        resourceNamePlural={t('transactions.table.resourceNamePlural')}
        onRowClick={(entity: ColumnData) => {
          selectTransactionId(entity.id);
          onOpen();
        }}
        emptyComponent={
          <EmptyListCta
            title={t('transactions.emptyListCta.title')}
            description={t('transactions.emptyListCta.description')}
            Icon={TransactionIcon}
            buttonText={t('transactions.emptyListCta.buttonText')}
            onClick={(): void => {
              navigate('/overview');
            }}
          />
        }
      />
      {selectedTransaction && (
        <EditTransactionModal
          isOpen={isOpen}
          onClose={() => {
            onClose();
            selectTransactionId(undefined);
          }}
          transaction={selectedTransaction}
        />
      )}
    </Flex>
  );
}

export { TransactionTable };
