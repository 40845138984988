import { useMutation } from '@apollo/client';
import { EditIcon } from '@chakra-ui/icons';
import { Flex, IconButton, Input, Skeleton, Text, Tooltip } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AccountTag } from 'src/components/transactions/AccountTag';
import {
  SET_TRANSACTION_NOTES,
  type SetTransactionNotesData,
  type SetTransactionNotesInput,
} from 'src/graphql/SetTransactionNotes';
import { type ISetTransactionPayee, SetTransactionPayee } from 'src/graphql/SetTransactionPayee';
import type { GetTransactionsQuery } from 'src/graphql/__generated__/graphql';
import { formatCurrency } from 'src/util';

type Transaction = GetTransactionsQuery['transactions']['transactions'][0];

interface TransactionCardProps<Entity extends Transaction> {
  entity: Entity;
  loading: boolean;
  index: number;
}

const Payee = ({ payee, transactionId }: { payee: string; transactionId: string }) => {
  const { t } = useTranslation();
  const [isEditingPayee, setEditingPayee] = useState<boolean>(false);
  const payeeInputRef = useRef<HTMLInputElement>(null);
  const [tmpPayee, setTmpPayee] = useState<string>(payee);

  useEffect(() => {
    if (isEditingPayee && payeeInputRef.current) {
      payeeInputRef.current.focus();
    }
  }, [isEditingPayee]);

  const [setTransactionPayee] = useMutation<
    ISetTransactionPayee['output'],
    ISetTransactionPayee['input']
  >(SetTransactionPayee.query, {
    optimisticResponse: SetTransactionPayee.optimisticResponse({
      affectedTransactionIds: [transactionId],
    }),
    update: SetTransactionPayee.update,
    onError: (_err) => {
      // NOTE: Toast display is handled by the error link,
      //  but this is needed to avoid unhandled promise rejection errors
    },
  });

  const savePayee = () => {
    setEditingPayee(false);
    if (payee !== tmpPayee) {
      setTransactionPayee({ variables: { transactionId, payee: tmpPayee } });
    }
  };

  return isEditingPayee ? (
    <Input
      flex={1}
      borderRadius={6}
      ref={payeeInputRef}
      variant={'outline'}
      value={tmpPayee}
      size={'sm'}
      enterKeyHint="done"
      onKeyUp={(e) => {
        // Prevent category button key shortcuts from triggering
        e.stopPropagation();
      }}
      onBlur={() => {
        savePayee();
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          payeeInputRef.current?.blur();
        }
      }}
      onChange={(e) => {
        setTmpPayee(e.target.value);
      }}
    />
  ) : (
    <Flex gap={2} alignItems="center" overflow="hidden">
      <Tooltip label={payee}>
        <Text fontSize={'xl'} fontWeight="semibold" noOfLines={1} flex={1}>
          {payee}
        </Text>
      </Tooltip>
      <IconButton
        colorScheme={'gray'}
        variant="ghost"
        size={'sm'}
        icon={<EditIcon />}
        aria-label={t('categorize.transactions.payeeEditButton')}
        borderRadius={8}
        onClick={() => {
          setEditingPayee(true);
        }}
      />
    </Flex>
  );
};

export const TransactionCard = <Entity extends Transaction>({
  entity,
  loading,
  index,
}: TransactionCardProps<Entity>) => {
  const { t } = useTranslation();
  const [notes, setNotes] = useState<string>(entity.notes ?? '');
  const notesInputRef = useRef<HTMLInputElement>(null);

  const [setTransactionNotes] = useMutation<SetTransactionNotesData, SetTransactionNotesInput>(
    SET_TRANSACTION_NOTES,
    {
      optimisticResponse: {
        setTransactionNotes: {
          id: entity.id,
          notes,
          __typename: 'Transaction',
        },
      },
      onError: (_err) => {
        // NOTE: Toast display is handled by the error link,
        //  but this is needed to avoid unhandled promise rejection errors
      },
    },
  );

  return (
    <Skeleton isLoaded={!loading} w={'full'}>
      <Flex gap={6} flexDir="column" width={'100%'} overflow="hidden">
        <Flex flexDir="column" gap={1}>
          <Flex flexDir={'column'} flex={1}>
            <Flex justifyContent={'space-between'} alignItems="center" width="100%" gap={1}>
              <Payee payee={entity.payee} transactionId={entity.id} />
              <Text fontSize={'lg'} flexShrink={0} textAlign="right">
                {formatCurrency({ cents: entity.amount, currencyCode: entity.currencyCode })}
              </Text>
            </Flex>
            <Text color="subtleText" noOfLines={1}>
              {`${entity.date.toLocaleDateString()} • ${entity.rawPayee}`}
            </Text>
          </Flex>
          <Flex gap={1}>
            <AccountTag name={entity.account.nickname} />
          </Flex>
        </Flex>

        <Input
          ref={notesInputRef}
          borderRadius={6}
          variant={'outline'}
          placeholder={t('categorize.transactions.notesInputPlaceholder') ?? undefined}
          type="text"
          enterKeyHint="done"
          autoComplete="off"
          size={'sm'}
          value={notes}
          tabIndex={index === 0 ? 0 : -1}
          onKeyUp={(e) => {
            // Prevent category button key shortcuts from triggering
            e.stopPropagation();
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              notesInputRef.current?.blur();
            }
          }}
          onBlur={(_e) => {
            if (notes !== entity.notes) {
              setTransactionNotes({ variables: { transactionId: entity.id, notes } });
            }
          }}
          onChange={(e) => {
            setNotes(e.target.value);
          }}
        />
      </Flex>
    </Skeleton>
  );
};
