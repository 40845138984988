import { StrictMode } from 'react';
import { init } from '@sentry/react';
import './i18n/config';
import './util/monkeyPatch';
import './util/crisp';

import {
  ChakraProvider,
  type ColorModeProviderProps,
  createStandaloneToast,
} from '@chakra-ui/react';
import {
  ApolloClient,
  ApolloProvider,
  type NormalizedCacheObject,
  from,
  createHttpLink,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import { TransactionsApp } from './components/transactions/TransactionsApp';
import { cache } from './cache';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { RecurringTransactionApp } from './components/recurringTransactions/RecurringTransactionApp';
import { InsightsApp } from './components/insights/InsightsApp';
import { theme } from '@flume-finance/ui';
import { LoginApp, REDIRECT_REASONS } from './components/login/LoginApp';
import { PrivateRoute } from './components/shared/PrivateRoute';
import { PublicOnlyRoute } from './components/shared/PublicOnlyRoute';
import { BudgetApp } from './components/budget/BudgetApp';
import { AutomationApp } from './components/automation/AutomationApp';
import { CategoryApp } from './components/settings/category/CategoryApp';
import { SignUpApp } from './components/login/SignUpApp';
import { handleLogout } from './util/authentication';
import { NotFound } from './components/shared/NotFound';
import { OverviewApp } from './components/overview/OverviewApp';
import { SettingsApp } from './components/settings/SettingsApp';
import { BillingPage } from './components/settings/billing/BillingPage';
import { PlaidOauthRedirect } from './components/plaid/PlaidOauthRedirect';
import { createRoot } from 'react-dom/client';
import { VerifyEmailApp } from './components/login/VerifyEmailApp';
import { SubscriptionApp } from './components/login/SubscriptionApp';
import { CategorizeApp } from './components/categorize/CategorizeApp';
import { NotificationsPage } from './components/settings/notifications/NotificationsPage';
import { AccountPage } from './components/settings/account/AccountPage';
import { withScalars } from 'apollo-link-scalars';
import introspectionResult from './graphql/__generated__/graphql.schema.json';
import { buildClientSchema, type IntrospectionQuery } from 'graphql';

const schema = buildClientSchema(introspectionResult as unknown as IntrospectionQuery);

const httpLink = createHttpLink({
  uri: `${import.meta.env.VITE_API_SERVER_BASE_URL}/graphql`,
  credentials: 'include',
});

const { ToastContainer, toast } = createStandaloneToast({ theme });

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    for (const { message } of graphQLErrors) {
      const TOAST_ID = 'graphql-error';
      // Logout when an authorization error occurs
      if (message === 'Unauthorized') {
        handleLogout({
          params: {
            redirectReason: REDIRECT_REASONS.SESSION_EXPIRATION,
          },
        });
      } else {
        if (!toast.isActive(TOAST_ID)) {
          toast({
            id: TOAST_ID,
            title: message,
            status: 'error',
            duration: 5_000,
          });
        }
      }
    }
  }

  if (networkError) {
    const TOAST_ID = 'network-error';
    if (!toast.isActive(TOAST_ID)) {
      toast({
        id: TOAST_ID,
        title: 'A network error occurred',
        description: networkError.message,
        status: 'error',
        duration: 7_000,
      });
    }
  }
});

const typesMap = {
  /** Bigints are received/ sent as strings, but handled as native BigInts inside the app  */
  Bigint: {
    serialize: (parsed: unknown): string => {
      if (typeof parsed === 'bigint') {
        return parsed.toString();
      }

      throw new Error(`Invalid valid value "${parsed}" cannot be serialized as BigInt`);
    },

    parseValue: (raw: unknown): bigint => {
      if (typeof raw === 'string') {
        return BigInt(raw);
      }

      throw new Error(`Invalid valid value "${raw}" cannot be parsed into BigInt`);
    },
  },
  /** DateTimes are received/ sent as ISO date strings, but handled as native Date inside the app */
  DateTime: {
    serialize: (parsed: unknown): string => {
      if (parsed instanceof Date) {
        return parsed.toISOString();
      }

      throw new Error(`Invalid valid value "${parsed}" cannot be serialized as DatTime`);
    },
    parseValue: (raw: unknown): Date => {
      if (typeof raw === 'string') {
        return new Date(raw);
      }

      throw new Error(`Invalid valid value "${raw}" cannot be parsed into DateTime`);
    },
  },
};

export const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  link: from([errorLink, withScalars({ schema, typesMap }), httpLink]),
  cache,
});

init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  tracesSampleRate: 0,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  release: import.meta.env.VITE_SENTRY_RELEASE,
});

const container = document.getElementById('root');
if (container === null) {
  throw new Error('Cannot find container root to mount react application');
}

const systemColorModeManager: ColorModeProviderProps['colorModeManager'] = {
  type: 'localStorage',
  get: () => (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'),
  set: (_val) => {
    /** Do nothing which prevents an entry from being written to local storage*/
  },
  ssr: false,
};

const root = createRoot(container);
root.render(
  <StrictMode>
    <ChakraProvider theme={theme} colorModeManager={systemColorModeManager}>
      <ApolloProvider client={client}>
        <Router>
          <Routes>
            <Route
              path="/overview"
              element={
                <PrivateRoute>
                  <OverviewApp />
                </PrivateRoute>
              }
            />
            <Route
              path="/transactions"
              element={
                <PrivateRoute>
                  <TransactionsApp />
                </PrivateRoute>
              }
            />
            <Route
              path="/budget"
              element={
                <PrivateRoute>
                  <BudgetApp />
                </PrivateRoute>
              }
            />
            <Route
              path="/recurring"
              element={
                <PrivateRoute>
                  <RecurringTransactionApp />
                </PrivateRoute>
              }
            />
            <Route
              path="/insights"
              element={
                <PrivateRoute>
                  <InsightsApp />
                </PrivateRoute>
              }
            />
            <Route
              path="/categories"
              element={
                <PrivateRoute>
                  <CategoryApp />
                </PrivateRoute>
              }
            />
            <Route
              path="/categorize"
              element={
                <PrivateRoute>
                  <CategorizeApp />
                </PrivateRoute>
              }
            />
            <Route
              path="/settings"
              element={
                <PrivateRoute>
                  <SettingsApp />
                </PrivateRoute>
              }
            >
              <Route index element={<Navigate to="account" replace />} />
              <Route
                path="account"
                element={
                  <PrivateRoute>
                    <AccountPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="notifications"
                element={
                  <PrivateRoute>
                    <NotificationsPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="billing"
                element={
                  <PrivateRoute>
                    <BillingPage />
                  </PrivateRoute>
                }
              />
            </Route>
            <Route
              path="/automation"
              element={
                <PrivateRoute>
                  <AutomationApp />
                </PrivateRoute>
              }
            />
            <Route
              path="/login"
              element={
                <PublicOnlyRoute>
                  <LoginApp />
                </PublicOnlyRoute>
              }
            />
            <Route
              path="/signup"
              element={
                <PublicOnlyRoute>
                  <SignUpApp />
                </PublicOnlyRoute>
              }
            />
            <Route
              path="/verify-email"
              element={
                <PrivateRoute>
                  <VerifyEmailApp />
                </PrivateRoute>
              }
            />
            <Route
              path="/subscribe"
              element={
                <PrivateRoute>
                  <SubscriptionApp />
                </PrivateRoute>
              }
            />
            <Route
              path="/plaid-oauth"
              element={
                <PrivateRoute>
                  <PlaidOauthRedirect />
                </PrivateRoute>
              }
            />
            <Route path="/" element={<Navigate to="/overview" replace />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
        <ToastContainer />
      </ApolloProvider>
    </ChakraProvider>
  </StrictMode>,
);
