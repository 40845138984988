import { useMutation, useQuery } from '@apollo/client';
import { GET_TRANSACTIONS, GET_TRANSACTIONS_PLACEHOLDER } from 'src/graphql/GetTransactions';
import {
  categoryOptimisticResponse,
  type SetCategoryData,
  type SetCategoryInput,
  SET_CATEGORY,
} from 'src/graphql/SetCategory';
import {
  type SetTransactionReviewedData,
  type SetTransactionReviewedInput,
  SET_TRANSACTION_REVIEWED,
} from 'src/graphql/SetTransactionReviewed';
import { getQueryData } from 'src/util/graphql';
import { TransactionCard } from 'src/components/categorize/cards/TransactionCard';
import { type Action, CategorizeSection } from 'src/components/categorize/CategorizeSection';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import DoneIcon from 'src/assets/icons/icons8-done.svg?react';
import { EmptyListCta } from 'src/components/shared/EmptyListCta';
import { ReviewedFilter } from 'src/graphql/__generated__/graphql';

export const TransactionCategorizeSection = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [setTransactionReviewed] = useMutation<
    SetTransactionReviewedData,
    SetTransactionReviewedInput
  >(SET_TRANSACTION_REVIEWED);

  const [mutateCategory] = useMutation<SetCategoryData, SetCategoryInput>(SET_CATEGORY, {
    onError: (_err) => {
      // NOTE: Toast display is handled by the error link,
      //  but this is needed to avoid unhandled promise rejection errors
    },
  });

  const { data, loading, fetchMore } = useQuery(GET_TRANSACTIONS, {
    variables: {
      cursor: null,
      sort: { fieldId: 'date', desc: true },
      filters: {
        category: null,
        reviewed: ReviewedFilter.NeedsReview,
      },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });
  const {
    isInitialLoaded,
    queryData: {
      transactions: { transactions },
    },
  } = getQueryData(data, GET_TRANSACTIONS_PLACEHOLDER);

  /**
   * Fetch more transactions if we have less than 2x the number of cards display left in cache.
   * This help makes the loading process seamless since the cards are fetched in advance of needing
   * to render them.
   */
  const maybeFetchMore = (offset: number, numCardsDisplayed: number) => {
    const nextCursor = data?.transactions.nextCursor;
    if (
      offset + 1 >= transactions.length - numCardsDisplayed * 2 &&
      loading === false &&
      nextCursor
    ) {
      fetchMore({
        variables: {
          cursor: nextCursor,
        },
      });
    }
  };

  const updateEntity = (mode: Action, transactionId: string, categoryId?: string) => {
    if (categoryId && mode === 'review') {
      mutateCategory({
        variables: { transactionId: transactionId, categoryId },
        optimisticResponse: categoryOptimisticResponse(transactionId, categoryId),
      });
    }

    if (mode === 'review' || mode === 'undo') {
      const reviewed = mode === 'review';
      setTransactionReviewed({
        variables: {
          transactionId,
          reviewed,
        },
        optimisticResponse: {
          setTransactionReviewed: {
            id: transactionId,
            reviewed,
            __typename: 'Transaction',
          },
        },
      });
    }
  };

  return (
    <CategorizeSection
      entities={transactions}
      CompleteComponent={
        <EmptyListCta
          title={t('categorize.transactions.emptyListCta.title')}
          description={t('categorize.transactions.emptyListCta.description')}
          Icon={DoneIcon}
          buttonText={t('categorize.transactions.emptyListCta.buttonText')}
          onClick={() => {
            navigate('/insights');
          }}
        />
      }
      entityType={'transactions'}
      total={data?.transactions.total ?? 0}
      isLoaded={isInitialLoaded}
      updateEntity={updateEntity}
      fetchMore={maybeFetchMore}
      Card={TransactionCard}
    />
  );
};
