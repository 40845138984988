import { type Reference, useMutation, useQuery } from '@apollo/client';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import {
  Divider,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  type useDisclosure,
} from '@chakra-ui/react';
import { type ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AssetIcon from 'src/assets/icons/icons8-ledger.svg?react';
import { NumberField } from 'src/components/overview/createAccountFlow/CreateEditAccountForm';
import { EmptyListCta } from 'src/components/shared/EmptyListCta';
import { ErrorWithRefetch } from 'src/components/shared/ErrorWithRefetch';
import { SharedTable } from 'src/components/shared/SharedTable';
import { CreateAssetValue, type ICreateAssetValue } from 'src/graphql/CreateAssetValue';
import { DeleteAssetValue } from 'src/graphql/DeleteAssetValue';
import { GET_ASSET, GET_ASSET_PLACEHOLDER } from 'src/graphql/GetAsset';
import type { AssetValue, GetAssetQuery } from 'src/graphql/__generated__/graphql';
import { formatCurrency } from 'src/util';
import { now, dayjs } from 'src/util/dayjs';

type ColumnData = NonNullable<GetAssetQuery['asset']['assetValues']>[0];

export const EditBalancesPanel = ({
  asset,
}: {
  asset: GetAssetQuery['asset'];
  onClose: EditAccountModalProps['onClose'];
}) => {
  const { t } = useTranslation();
  const [dateStr, setDateStr] = useState<string>(now().format('YYYY-MM-DD'));
  const [balance, setBalance] = useState<bigint>(0n);

  const [createAssetValue] = useMutation<ICreateAssetValue['output'], ICreateAssetValue['input']>(
    CreateAssetValue.query,
    {
      optimisticResponse: CreateAssetValue.optimisticResponse({
        id: crypto.randomUUID(),
        // NOTE: Using close of US stock market for hour instead of midnight so that it looks more correct across timezones.
        date: dayjs(dateStr).set('hour', 17).toDate(),
        value: balance,
      }),
      update: CreateAssetValue.update,
    },
  );

  const [deleteAssetValue] = useMutation(DeleteAssetValue.query, {
    optimisticResponse: DeleteAssetValue.optimisticResponse({ assetValueId: crypto.randomUUID() }),
    update: (cache, _context, { variables }) => {
      cache.modify<{ assetValues: Reference[] }>({
        id: cache.identify({ __typename: 'Asset', id: asset.id }),
        fields: {
          assetValues(existingAssetValueRef, { readField }) {
            return existingAssetValueRef.filter(
              (assetValueRef) => readField('id', assetValueRef) !== variables?.assetValueId,
            );
          },
        },
      });
    },
  });

  const columnHelper = createColumnHelper<ColumnData>();
  // biome-ignore lint/suspicious/noExplicitAny: Quirk of @tanstack/react-table and typescript
  const columns: ColumnDef<ColumnData, any>[] = useMemo(
    () => [
      columnHelper.accessor((a) => a.date, {
        id: 'date',
        header: 'Date',
        enableSorting: false,
        enableColumnFilter: false,
        meta: {
          flexGrow: 1,
          flexBasis: 200,
        },
        cell: (props) => {
          return (
            <Flex h={8} alignItems="center">
              <Text>{new Date(props.row.original.date).toLocaleDateString()}</Text>
            </Flex>
          );
        },
      }),
      columnHelper.accessor((a) => a.value, {
        id: 'value',
        header: 'Balance',
        enableSorting: false,
        enableColumnFilter: false,
        meta: {
          flexGrow: 1,
          flexBasis: 180,
        },
        cell: (props) => {
          return (
            <Flex h={8} alignItems="center">
              <Text>
                {formatCurrency({
                  cents: props.getValue<AssetValue['value']>(),
                  currencyCode: asset.currencyCode,
                })}
              </Text>
            </Flex>
          );
        },
      }),
      columnHelper.display({
        id: 'rowActions',
        meta: {
          flexBasis: '60px',
        },
        cell: (props) => {
          const assetValueId = props.row.original.id;
          return (
            <Flex alignItems="center">
              <IconButton
                colorScheme={'gray'}
                variant="ghost"
                size="sm"
                icon={<DeleteIcon color={'iconColor'} />}
                aria-label={t('automations.table.deleteAriaLabel') ?? undefined}
                borderRadius={8}
                onClick={() => {
                  deleteAssetValue({
                    variables: {
                      assetValueId,
                    },
                  });
                }}
              />
            </Flex>
          );
        },
      }),
    ],
    [columnHelper, asset.currencyCode, deleteAssetValue, t],
  );

  const onSubmit = () => {
    createAssetValue({
      variables: {
        // NOTE: Using close of US stock market for hour instead of midnight so that it looks more correct across timezones.
        date: dayjs(dateStr).set('hour', 17).toDate(),
        balance,
        assetId: asset.id,
      },
    });

    setDateStr(dayjs(dateStr).add(3, 'months').format('YYYY-MM-DD'));
    setBalance(0n);
  };

  return (
    <Flex flex={1} h={'30rem'} flexDirection="column" gap={2}>
      <Flex alignItems={'flex-end'} gap={2}>
        <FormControl id="budgeted">
          <FormLabel fontSize="xs" color="subtleText" textTransform={'uppercase'} fontWeight="bold">
            Date
          </FormLabel>
          <Input
            type="date"
            value={dateStr}
            onChange={(e) => {
              setDateStr(e.currentTarget.value);
            }}
          />
        </FormControl>
        <NumberField
          fieldId="balance"
          label="Balance"
          currencyCode={asset.currencyCode}
          value={balance.toString()}
          onChange={(val) => setBalance(BigInt(val))}
          helperText=""
          hasBeenSubmitted={false}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              onSubmit();
            }
          }}
        />
        <IconButton aria-label="Remove Username" icon={<AddIcon />} onClick={onSubmit} />
      </Flex>
      <Divider mt={2} />
      <SharedTable
        columns={columns}
        data={asset.assetValues ?? []}
        fetchMore={() => {
          /* do nothing */
        }}
        hasMore={false}
        isInitialLoaded={true}
        isLoading={false}
        onRowClick={(_entity: ColumnData) => {
          /* do nothing */
        }}
        showHeadingBreadcrumbs={false}
        resourceNamePlural={'Asset Values'}
        error={undefined}
        emptyComponent={
          <EmptyListCta
            title={'Asset Balance History'}
            description={
              'This Asset does not have any historical balances in Flume. You can add the balance on a given date above.'
            }
            // TODO: Find a better icon for this, maybe a chart upward trend
            Icon={AssetIcon}
          />
        }
      />
    </Flex>
  );
};

interface EditAccountModalProps {
  isOpen: boolean;
  onClose: ReturnType<typeof useDisclosure>['onClose'];
  assetId: GetAssetQuery['asset']['id'] | undefined;
}
export const EditAssetModal = ({ isOpen, onClose, assetId }: EditAccountModalProps) => {
  const { data, loading, error, refetch } = useQuery(GET_ASSET, {
    variables: { id: assetId as string },
    skip: assetId === undefined,
  });

  const asset = data === undefined ? GET_ASSET_PLACEHOLDER.asset : data.asset;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" trapFocus={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Skeleton isLoaded={!loading && !error} flexDirection="row" w="fit-content">
            <Flex gap={2}>
              <Text isTruncated>{asset.name}</Text>
              <Text color="gray.500" textTransform={'capitalize'}>
                {asset.type}
              </Text>
            </Flex>
          </Skeleton>
        </ModalHeader>
        <ModalBody>
          <Tabs variant={'enclosed'}>
            <TabList>
              <Tab>Balances</Tab>
            </TabList>
            {error && (
              <Flex p={4}>
                <ErrorWithRefetch
                  message="There was an error loading your asset's balances."
                  refetch={refetch}
                />
              </Flex>
            )}
            <TabPanels hidden={!!error}>
              <TabPanel>
                <Skeleton isLoaded={!loading}>
                  <EditBalancesPanel asset={asset} onClose={onClose} />
                </Skeleton>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  );
};
