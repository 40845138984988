import { useQuery, useReactiveVar } from '@apollo/client';
import {
  Flex,
  Heading,
  Spinner,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react';
import { getColor } from '@chakra-ui/theme-tools';
import { theme } from '@flume-finance/ui';
import { Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import type {
  Formatter,
  HorizontalAlignmentType,
  VerticalAlignmentType,
} from 'recharts/types/component/DefaultLegendContent';
import type { LayoutType } from 'recharts/types/util/types';
import { graphInterval } from 'src/cache';
import { intervalToChartParams } from 'src/components/insights/YearPicker';
import { ErrorWithRefetch } from 'src/components/shared/ErrorWithRefetch';
import {
  GET_CATEGORY_INSIGHT,
  type GetCategoryAggregationData,
  type GetCategoryAggregationInput,
} from 'src/graphql/GetCategoryAggregation';
import { formatCurrency, sum, sumObj } from 'src/util';
import { unix } from 'src/util/dayjs';

export const CategoryPie = () => {
  const graphIntervalValue = useReactiveVar(graphInterval);
  const chartParams = intervalToChartParams(graphIntervalValue);
  const { loading, error, data } = useQuery<
    GetCategoryAggregationData,
    GetCategoryAggregationInput
  >(GET_CATEGORY_INSIGHT, {
    variables: {
      startDate: unix(chartParams.startDate).toDate(),
      endDate: unix(chartParams.endDate).toDate(),
      interval: chartParams.aggregationInterval,
    },
    fetchPolicy: 'cache-and-network',
  });

  const legendVerticalAlignment = useBreakpointValue<VerticalAlignmentType>(
    {
      base: 'bottom',
      md: 'middle',
    },
    { ssr: false },
  );
  const legendHorizontalAlignment = useBreakpointValue<HorizontalAlignmentType>(
    {
      base: 'center',
      md: 'right',
    },
    { ssr: false },
  );
  const legendLayout = useBreakpointValue<LayoutType>(
    {
      base: 'horizontal',
      md: 'vertical',
    },
    { ssr: false },
  );

  const cursorBgColor = useColorModeValue('gray.100', 'gray.700');
  const gridColor = useColorModeValue(theme.colors.gray[200], theme.colors.gray[600]);
  const fontColor = useColorModeValue(theme.colors.black, theme.colors.white);

  const fontProps = {
    stroke: getColor(theme, fontColor),
    fontSize: 12,
    fontWeight: 500,
    fontFamily: theme.fonts.body,
    color: fontColor,
  };

  const legendTextFormatter: Formatter = (value) => {
    return <span style={{ ...fontProps, padding: '0.5rem' }}>{value}</span>;
  };

  if (error) return <ErrorWithRefetch message={'Failed to load chart'} />;

  if (data === undefined) {
    return (
      <Flex justifyContent="center" alignItems="center" height={'100%'}>
        <Spinner size="lg" />
      </Flex>
    );
  }

  const dataset = data.categoryAggregation.aggregations
    .map((agg) => {
      return {
        name: agg.category.name,
        value: sum(agg.amounts),
        fill: getColor(theme, `${agg.category.color ?? 'gray'}.400`),
      };
    })
    .filter((c) => c.value < 0)
    .map((c) => ({ ...c, value: Math.abs(c.value) }));

  const total = -sumObj(dataset, (ds) => ds.value);

  return (
    <>
      <Flex justifyContent="space-between" alignItems="flex-start">
        <Heading as="h2" marginBottom={5}>
          {formatCurrency({ cents: total, currencyCode: 'USD' })}
        </Heading>
        {loading && (
          <Flex alignItems="center">
            <Spinner size={'sm'} mr={3} />
            <Text>Refreshing...</Text>
          </Flex>
        )}
      </Flex>
      {dataset.length === 0 && <Text fontSize="lg">No data to display</Text>}
      <ResponsiveContainer width={'100%'} height={'90%'} minWidth={0}>
        <PieChart>
          <Pie
            data={dataset}
            nameKey="name"
            dataKey="value"
            innerRadius={'55%'}
            outerRadius={'75%'}
          />
          <Tooltip
            formatter={(val) =>
              formatCurrency({ cents: Number(val), currencyCode: 'USD', hideCents: true })
            }
            cursor={{ strokeWidth: 0, fill: getColor(theme, cursorBgColor) }}
            labelStyle={{ ...fontProps, fontSize: 12 } as React.CSSProperties}
            itemStyle={{ ...fontProps, fontSize: 16 } as React.CSSProperties}
            contentStyle={{
              borderRadius: 8,
              backgroundColor: getColor(theme, cursorBgColor),
              borderColor: getColor(theme, gridColor),
            }}
          />
          <Legend
            iconType="circle"
            layout={legendLayout}
            verticalAlign={legendVerticalAlignment}
            align={legendHorizontalAlignment}
            iconSize={10}
            formatter={legendTextFormatter}
          />
        </PieChart>
      </ResponsiveContainer>
    </>
  );
};
