import { CATEGORY_COLORS } from '@flume-finance/common';
import { gql } from 'src/graphql/__generated__/gql';
import type { GetTransactionsQuery } from 'src/graphql/__generated__/graphql';

export const GET_TRANSACTIONS = gql(/* GraphQL */ `
  query GetTransactions(
    $accountId: String
    $cursor: String
    $sort: SortOption
    $filters: FilterOptions!
  ) {
    transactions(accountId: $accountId, cursor: $cursor, sort: $sort, filters: $filters) {
      nextCursor
      total
      transactions {
        id
        payee
        rawPayee
        notes
        date
        amount
        currencyCode
        reviewed
        hidden
        category {
          id
          name
          color
        }
        merchant {
          name
          category
        }
        account {
          id
          nickname
        }
        linkedTransfer {
          id
          inflowTransaction {
            id
          }
          outflowTransaction {
            id
          }
        }
      }
    }
  }
`);

export const GET_TRANSACTIONS_PLACEHOLDER: GetTransactionsQuery = {
  transactions: {
    nextCursor: null,
    total: 30,
    transactions: Array.from(Array(30), (_, index) => ({
      id: `${index}`,
      payee: 'Uber',
      rawPayee: 'UBER FRI 9PM UBER.COM',
      notes: 'UES to Midtown',
      date: new Date(),
      amount: 1000n,
      currencyCode: 'USD',
      reviewed: true,
      hidden: false,
      category: {
        id: `${index + 100}`,
        name: 'Transportation',
        color: CATEGORY_COLORS[0],
      },
      merchant: {
        name: 'Uber',
        category: 'Transportation',
      },
      account: {
        id: `${index + 200}`,
        nickname: 'Credit Card',
      },
      linkedTransfer: null,
    })),
  },
};
