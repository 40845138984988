import { useMutation, useQuery } from '@apollo/client';
import {
  Alert,
  AlertIcon,
  type AlertProps,
  Button,
  Container,
  Flex,
  Heading,
  Link,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom';
import { GET_CURRENT_USER, getCurrentUserCallback } from 'src/graphql/GetCurrentUser';
import { LOGIN, type LoginInput, type LoginPayload } from 'src/graphql/Login';
import { handleLogin } from 'src/util/authentication';
import { EmailField } from './fields/EmailField';
import { PasswordField } from './fields/PasswordField';

export function LoginApp() {
  const { refetch: refetchCurrentUser, loading: currentUserLoading } = useQuery(GET_CURRENT_USER, {
    skip: true,
    onCompleted: getCurrentUserCallback,
  });

  const [email, setEmail] = useState('');
  const emailFieldRef = useRef<HTMLInputElement>(null);

  const [password, setPassword] = useState('');
  const passwordFieldRef = useRef<HTMLInputElement>(null);

  const [hasFormSubmitted, setFormSubmitted] = useState(false);

  const navigate = useNavigate();

  const [mutate, { loading }] = useMutation<LoginPayload, LoginInput>(LOGIN, {
    onCompleted: () => {
      refetchCurrentUser().then(() => {
        handleLogin(navigate);
      });
    },
    onError: (_err) => {
      // NOTE: Toast display is handled by the error link,
      //  but this is needed to avoid unhandled promise rejection errors
    },
  });

  const login = () => {
    setFormSubmitted(true);
    const isFormValid = [passwordFieldRef, emailFieldRef].every(
      (ref) => ref.current?.validity.valid,
    );
    if (isFormValid) {
      mutate({ variables: { email, password } });
    }
  };

  return (
    <Flex
      flexGrow={1}
      align="center"
      justify="center"
      height="100%"
      flexDir={'column'}
      p={{ base: 2, sm: 0 }}
      gap={4}
    >
      <RedirectAlert />
      <Heading size="lg" mb={5}>
        Sign in to Flume
      </Heading>
      <Container
        boxShadow="lg"
        variant={'card'}
        padding={{ base: 4, sm: 8 }}
        maxW={'25rem'}
        mb={2}
        display="flex"
        flexDir={'column'}
        gap={3}
      >
        <EmailField
          value={email}
          isInvalid={hasFormSubmitted && !emailFieldRef.current?.validity.valid}
          fieldRef={emailFieldRef}
          onChange={(e) => setEmail(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter' && passwordFieldRef.current) {
              passwordFieldRef.current.focus();
            }
          }}
        />
        <PasswordField
          isInvalid={hasFormSubmitted && !passwordFieldRef.current?.validity.valid}
          value={password}
          fieldRef={passwordFieldRef}
          onChange={(e) => setPassword(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              login();
            }
          }}
          autoComplete={'current-password'}
        />

        <Button
          type="submit"
          isLoading={loading || currentUserLoading}
          onClick={login}
          alignSelf="flex-end"
          size="md"
          mt={4}
        >
          Sign In
        </Button>
      </Container>
      <Link as={RouterLink} to="/signup" colorScheme={'blue'} fontWeight="semibold" fontSize={'sm'}>
        New to Flume? Create an account.
      </Link>
    </Flex>
  );
}

export const REDIRECT_REASONS = {
  USER_DELETION: 'USER_DELETION',
  SESSION_EXPIRATION: 'SESSION_EXPIRATION',
};

const RedirectAlert = () => {
  const [searchParams, _] = useSearchParams();

  const REASONS: Record<string, { status: AlertProps['status']; message: string }> = {
    [REDIRECT_REASONS.USER_DELETION]: {
      status: 'success',
      message: 'Your account has been successfully deleted.',
    },
    [REDIRECT_REASONS.SESSION_EXPIRATION]: {
      status: 'warning',
      message: 'Your session has expired, please login',
    },
  };

  const redirectReason = searchParams.get('redirectReason');
  if (redirectReason === null || !Object.keys(REASONS).includes(redirectReason)) {
    return null;
  }

  const reason = REASONS[redirectReason];
  return (
    <Alert status={reason.status} maxW={'25rem'}>
      <AlertIcon />
      {reason.message}
    </Alert>
  );
};
